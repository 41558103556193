<!-- 搜索页面 -->
<template>
  <div class="frame baseMap">
    <div class="content">
      <div class="search-label">
        <span>{{ search }}</span>
        搜索结果如下：
      </div>
      <div class="box-list">
        <el-card v-for="item in list" :key="item.id">
          <div class="box-info" @click="getDetail(item)">
            <div class="left">
              <div class="top">
                <div class="time">
                  {{ $dayjs(item.publishTime || item.createTime).format("YYYY")
                  }}<br /><span>{{
                    $dayjs(item.publishTime || item.createTime).format("MM/DD")
                  }}</span>
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
              <div class="desc">{{ item.introduce }}</div>
            </div>
            <div class="right" v-if="item.imgUrl">
              <img :src="URL + item.imgUrl" :alt="item.title" />
            </div>
          </div>
        </el-card>

        <el-pagination
          :hide-on-single-page="total <= queryParams.pageSize"
          @current-change="currentChange"
          :current-page.sync="queryParams.pageIndex"
          :page-size="queryParams.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "../../util/config";
export default {
  data() {
    return {
      URL,
      search: "",
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        publishFlag: 1,
        typeCode: "",
      }, // 新闻内容查询参数
      list: [], // 新闻列表
      total: 0, // 总新闻
    };
  },
  created() {
    this.search = this.$route.query.data;
    if (!this.search) {
      this.$router.replace("/login");
    }
    setTimeout(() => {
      window.scrollTo({ top: 400, behavior: "smooth" });
    }, 500);

    this.getList();
  },
  mounted() {},
  methods: {
    currentChange(val) {
      this.queryParams.pageIndex = val;
      this.getList();
    },
    // 获取新闻列表
    getList() {
      this.$api.searchNews({ content: this.search }).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    getDetail(item) {
      if (item.urlEnable === 1) {
        window.open(item.linkUrl);
      } else {
        this.$router.push("/newsDetail/" + item.id);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/style/color.scss";

.content {
  width: 1200px;
  min-height: 100px;
  margin: 0 auto 40px;
  padding: 20px;
  box-sizing: border-box;

  .search-label {
    font-weight: bold;
    font-size: 24px;

    span {
      font-size: 28px;
      color: red;
    }
  }

  .box-list {
    margin-top: 20px;
  }

  .el-card {
    margin-bottom: 30px;
    transition: transform 0.5s;

    &:hover {
      transform: translateY(-10px);
    }
  }

  .box-info {
    display: flex;
    cursor: pointer;

    &:hover {
      .left {
        .top {
          .title {
            color: $theme;
          }
        }
      }
      .right img {
        transform: scale(1.1);
      }
    }

    .left {
      flex-grow: 1;
      margin-right: 10px;

      .top {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;

        .time {
          font-size: 14px;
          color: #999;
          text-align: center;
          padding-right: 10px;
          margin-right: 10px;
          border-right: 1px solid #eee;

          span {
            font-size: 18px;
            color: #333;
          }
        }
        .title {
          max-width: 500px;
          font-size: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .desc {
        text-indent: 2em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 10px 0;
        white-space: pre-wrap;
      }
    }
    .right {
      width: 400px;
      height: 200px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s;
      }
    }
  }
}

@media (max-width: 750px) {
  .content {
    width: 100%;
    margin: 0;
    padding: 15px;

    .search-label {
      font-size: 18px;

      span {
        font-size: 20px;
      }
    }

    .box-info {
      display: block;

      .left {
        .top {
          .time {
            span {
              font-size: 16px;
            }
          }
          .title {
            font-size: 16px;
          }
        }
        .desc {
          display: none;
        }
      }
      .right {
        width: 100%;
        height: 170px;
      }
    }
  }
}
</style>
